<template>
  <div>
    <CModal
        data-sel="mdl-add-ra"
        title="New Revenue Account"
        :show.sync="showModal"
        size="lg"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <RevenueAccountForm
          v-bind:revenueAccountIn="revenueAccount"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import RevenueAccountForm from "@/apps/billing/components/RevenueAccountForm";

const createRevenueAccount = gql`
  mutation createRevenueAccount($revenueAccount: RevenueAccountInput){
    createRevenueAccount(revenueAccount: $revenueAccount) {
      name
      revenueAccountNumber
      contractDomain
      clientLocation
    }
  }
`

export default {
  name: "RevenueAccountAdd",
  components: {
    RevenueAccountForm
  },
  data(){
    return {
      revenueAccount: {},
      revenueAccountSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.revenueAccountSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createRevenueAccount,
          variables: {
            revenueAccount: this.revenueAccountSave
          }
        })
        .then(res => {
          this.$emit('add-item', res.data.createRevenueAccount);
          this.revenueAccount = {};
          this.revenueAccountSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.revenueAccount = {};
        this.revenueAccountSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
