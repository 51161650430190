<template>
  <div data-sel="frm-ao">
    <CRow>
      <CCol sm="2">
        <CInput
            v-model="revenueAccount.revenueAccountNumber"
            v-on:change="updateItem"
            placeholder="Enter account number"
            description="Account Number"
            :is-valid="notNull"
            tooltip-feedback
            :readonly="!allowEdit"
            data-sel="ra-account-number"
        />
      </CCol>
      <CCol sm="5">
        <CInput
            v-model="revenueAccount.name"
            v-on:change="updateItem"
            placeholder="Enter Name"
            description="Name"
            :is-valid="notNull"
            tooltip-feedback
            :readonly="!allowEdit"
            data-sel="ra-name"
        />
      </CCol>
      <CCol sm="5">
        <CTextarea
            rows="1"
            v-model="revenueAccount.description"
            v-on:change="updateItem"
            :readonly="!allowEdit"
            placeholder="Add description"
            @input="revenueAccount.description = $event !== '' ? $event : null"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="3">
          <multiselect
            :disabled="!allowEdit"
            v-model="revenueAccount.clientLocation"
            @input="updateItem"
            :options="Object.keys(this.locationOptions)"
            :custom-label="(item) => this.locationOptions[item]"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="false"
            placeholder="Select client location"
            select-label="Select"
            deselect-label="Required field"
          />
          <CRow>
            <small class="form-text text-muted w-100">Location</small>
          </CRow>
        </CCol>
      <CCol sm="3">
        <multiselect
            :disabled="!allowEdit"
            v-model="revenueAccount.contractDomain"
            @input="updateItem"
            :options="Object.keys(this.domainOptions)"
            :custom-label="(item) => this.domainOptions[item]"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="true"
            placeholder="Select domain"
            select-label="Select"
            deselect-label="Remove"
        />
        <CRow>
          <small class="form-text text-muted w-100">Domain</small>
        </CRow>
      </CCol>
      <CCol sm="6">
        <multiselect
            :disabled="!allowEdit"
            v-model="revenueAccount.productGroups"
            @input="updateItem"
            :options="this.productGroups ? this.productGroups.data: []"
            :show-labels="true"
            label="name"
            track-by="name"
            :multiple="true"
            :searchable="true"
            :close-on-select="true"
            :allow-empty="true"
            select-label="Select"
            deselect-label="Remove"
            placeholder="Select product groups"
          />
          <CRow>
            <small class="form-text text-muted w-100">Product groups</small>
          </CRow>
        </CCol>
    </CRow>
  </div>
</template>

<script>
import gql from "graphql-tag";
const clonedeep = require('lodash.clonedeep')

const queryProductGroups = gql`
query {
  productGroups(order: "name") {
    data{
      nid
      name
    }
   }
}
`

export default {
  name: "RevenueAccountForm",
  props: ["revenueAccountIn"],
  data () {
    return {
      productGroups: [],
      domainOptions: {
        fertighaus_de: "fertighaus.de",
        fertighaus_at: "fertighaus.at",
        fachwerkhaus_de: "fachwerkhaus.de",
        baumarkt_de: "baumarkt.de",
        baddesign_de: "baddesign.de",
        traumbad_de: "traumbad.de",
        fliesenhandel_de: "fliesenhandel.de",
        luxus_fertighaus_de: "luxus-fertighaus.de",
        baumarkt_shopping_de: "baumarkt-shopping.de",
        kachelofen_online_de: "kachelofen-online.de",
        das_baulexikon_de: "das-baulexikon.de",
        handwerkersuche_de: "handwerkersuche.de",
        massivhaus_de: "massivhaus.de",
        einfamilienhaus_de: "einfamilienhaus.de",
        bungalow_de: "bungalow.de",
      },
      locationOptions : {
        germany: "Germany",
        eu: "EU",
        non_eu: "Non EU"
      }
    }
  },
  methods: {
    updateItem(){
      const data = clonedeep(this.revenueAccount)
      //multiselect object
      data.productGroups = this.revenueAccount.productGroups? this.revenueAccount.productGroups.map((item) => {return {nid: item.nid}}): null;
      this.$emit('update-item', data);
    },
    notNull(val) {
        return val !== undefined && val !== null && val !== ''
    }
  },
  apollo: {
    productGroups: {
      query: queryProductGroups,
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    }
  },
  computed: {
    revenueAccount(){
      return this.revenueAccountIn
    },
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    }
  },
  watch: {
    RevenueAccountIn(){
      this.updateItem();
    }
  }
}
</script>

<style scoped>
  .multiselect {
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
  }
  .col-img{
    padding: 0;
  }
</style>
